import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { formatDate } from '@/utils/fechas'

export function getListadoTrabajos(trabajos) {
  let trabajo = ''
  trabajos.forEach((job, index) => {
    if (index !== 0) trabajo += ', '
    trabajo += job.nombre
  })

  return trabajo.toUpperCase()
}

const jornada = [
  {
    id: 1,
    nombre: 'Diurno',
    value: 'diurno',
  },
  {
    id: 2,
    nombre: 'Nocturno',
    value: 'nocturno',
  },
]

// eslint-disable-next-line import/prefer-default-export
export function getListadoBrecha(tipo) {
  if (tipo === 1) return jornada
  // if (tipo === 5) return dañosAccesorios
  return []
}

const url = `${config.URL_BASE}brechas`
export async function updateCreateBrecha(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Gateway', err)
    return null
  }
}

export async function findBrecha(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getBrecha(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Ticket', err)
    return null
  }
}

function getClassRotate(item) {
  if (item.isMobile) return 'rotate_image'
  return 'no_rotate_image'
}

function getTrabajosRealizados(brecha) {
  return brecha.trabajosRealizados.map(trabajo => trabajo.nombre).join(', ')
}

export async function generarReporte(brecha) {
  const trabajosRealizados = getTrabajosRealizados(brecha)
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE BRECHA ÁREA DE ILUMINACIÓN</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
        margin-top: 1.1811in;
        margin-bottom: 0.7874in;
        margin-left: 0.7874in;
        margin-right: 0.7874in;
      }
    .headLogos{
        width: 100%;
        margin-bottom: 5px;
        border-collapse: collapse;
    }
    .letrasLogo{
        background-color: #1f88c2;
        color: white;
        font-family: sans-serif;
    }
    .tabla{
        border-collapse: collapse;
        width: 100%;
        font-family: sans-serif;
    }
    .tituloDatos {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 400px;
    }
    .tituloDetalle {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
    }
    th{
        border: 1px solid black;
        font-family: sans-serif;
    }
    td{
        font-family: sans-serif;
        padding-right: 15px;
        padding-left: 15px;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma2 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }
    .lineas {
        position: relative;
        width: 100%;
    }
    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 100%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .lineas hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .lineas span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
</style>
<body>
<table class="headLogos">
  <tr>
    <th width="66.64%" height="20px" class="letrasLogo"> <center>Reporte de Brecha Área de Iluminación<br>Dirección de Servicios Públicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">INFORMACIÓN GENERAL</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">POSTE</td>
    <td>
      <div class="linea">
        <span style="left: 25%; text-align:center; text-transform: uppercase; font-size:10.0pt">${brecha.poste}</span>
        <hr>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">BRIGADA</td>
    <td>
      <div class="linea">
        <span style="left: 25%; text-align:center; text-transform: uppercase; font-size:10.0pt">${brecha.brigada.nombre}</span>
        <hr>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">TIPO DE REPORTE</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%; text-align:center; text-transform: uppercase; font-size:10.0pt;">${brecha.tipoFalla.nombre}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">FECHA Y HORA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%; text-align:center; text-transform: uppercase; font-size:10.0pt">${formatDate(brecha.fechaCreacion, '/')}</span>
      </div>
    </td>
  </tr>
</table>
<br>
  <table style="border-collapse: collapse">
    <tr>
      <th class="tituloDatos">TRABAJO(S) REALIZADO(S)</th>
    </tr>
  </table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
      <div style="text-align:justify; margin-top: -20px; text-transform: uppercase; font-size:10.0pt"><p>${trabajosRealizados}</p></div>
        <hr>
      </div>
    </td>
  </tr>
</table>

${brecha.trabajosRealizados.find(item => item.id === 1)
    ? `
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">Metros de Brecha</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="lineas">
        <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">
        ${brecha.trabajosRealizados.find(item => item.id === 1)?.trabajo ? `${brecha.trabajosRealizados.find(item => item.id === 1).trabajo} METROS` : ''}
        </div>
        <hr>
      </div>
    </td>
  </tr>
</table>
`
    : ''}

${brecha.trabajosRealizados.find(item => item.id === 2)
    ? `
<br>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Brazo Retirado</th>
    <th class="tituloDatos">Brazo Nuevo</th>
  </tr>
  <tr>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${brecha.trabajosRealizados.find(item => item.id === 2)?.trabajo.brazoRetirado.nombre || ''}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${brecha.trabajosRealizados.find(item => item.id === 2)?.trabajo.brazoInstalado.nombre || ''}</div>
        <hr>
      </div>
    </th>
  </tr>
</table>
`
    : ''}
  
  ${brecha.trabajosRealizados.find(item => item.id === 3)
    ? `
<br>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Código de Lámpara Retirada</th>
    <th class="tituloDatos">Potencia de Lámpara Retirada</th>
  </tr>
  <tr>
  <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${brecha.trabajosRealizados.find(item => item.id === 3)?.trabajo.codigoAnterior || ''}</div>
        <hr>
      </div>
    </th>
    <th>
    <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${brecha.trabajosRealizados.find(item => item.id === 3)?.trabajo.potenciaAnterior || ''}</div>
        <hr>
      </div>
    </th>
  </tr>
</table>
<br>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Código de Lámpara Instalada</th>
    <th class="tituloDatos">Potencia de Lámpara Instalada</th>
  </tr>
  <tr>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${brecha.trabajosRealizados.find(item => item.id === 3)?.trabajo.codigoNuevo || ''}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${brecha.trabajosRealizados.find(item => item.id === 3)?.trabajo.potenciaNueva || ''}</div>
        <hr>
      </div>
    </th>
  </tr>
</table>
`
    : ''}
  
<br>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Fotografía Panorámica</th>
    <th class="tituloDatos">Fotografía Panorámica del Trabajo Final</th>
    ${brecha.seguimiento[2] && brecha.seguimiento[2].url
    ? '<th class="tituloDatos">Fotografía de Recolección y Limpieza de Área</th>' : ''}
  </tr>
  <tr>
    <th><img src="${brecha.seguimiento[0].url}" alt="img_0" width="400" height="400"></th>
    <th><img src="${brecha.seguimiento[1].url}" alt="img_1" width="400" height="400"></th>
    ${brecha.seguimiento[2] && brecha.seguimiento[2].url
    ? `<th><img src="${brecha.seguimiento[2].url}" alt="img_2" width="400" height="400"></th>` : ''}
  </tr>
</table>
<br>
<br>
<br>
<br>
<div>
    <hr class="lineaFirma2">
  </div>
  <div class="texto">
    <p><br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
  </div>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'landscape',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
